import Box from "../../../../common/components/Box";
import Heading from "../../../../common/components/Heading";
import Text from "../../../../common/components/Text";
import Container from "../../../../common/components/UI/Container";
import { SECTION_GOVERNMENT_DATA } from "../../../../common/data/SecD3v";
import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import SectionArea, {
  Row,
  Col,
  AboutTitle,
  ContactDetails,
  ColumnContents,
} from "./section.style";

const Section = () => {
  const { sections } = SECTION_GOVERNMENT_DATA;

  return (
    <>
      <SectionArea id="aboutUs">
        <Container>
          <Row className="rowContents">
            <Box className="blockTitle">
              <Heading as="h2" content={sections.about_us} />
              <Text as="p" content={sections.about_linetag} />
              <Text as="p" content={sections.about_text} />
            </Box>
            <Box className="blockTitle">
              <img
                className="imageBlockAbout"
                src={sections.imageAbout}
                alt=""
              />
            </Box>
          </Row>
        </Container>
      </SectionArea>
    </>
  );
};

export default Section;
