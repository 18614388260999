import Box from "../../../../common/components/Box";
import Heading from "../../../../common/components/Heading";
import Text from "../../../../common/components/Text";
import Container from "../../../../common/components/UI/Container";
import { ACROSS_GOVERNMET_DATA } from "../../../../common/data/SecD3v";
import React from "react";
import OurProductArea, { Col, Row } from "./ourProduct.style";
const OurProduct = () => {
  return (
    <OurProductArea id="product">
      <Container>
        <Box className="blockTitle">
          <Text as="p" content="" />
        </Box>
        <Row>
          {ACROSS_GOVERNMET_DATA?.map(({ icon, title, text }, index) => (
            <Col key={`service-post-key-${index}`}>
              <Box className="servicePost">
                <div
                  className="container-icon"
                  style={{ "--width": `${icon?.width}px` }}
                >
                  <img className="product-icon" src={icon} alt="" />
                </div>
                <Box className="content">
                  <Heading as="h3" content={title} />
                  <Text as="p" content={text} />
                </Box>
              </Box>
            </Col>
          ))}
        </Row>
      </Container>
    </OurProductArea>
  );
};

export default OurProduct;
